<template>
  <div>
    <b-card>
      test here
    </b-card>
  </div>
</template>

<script>
import ScrollLoader from 'vue-scroll-loader'
import {
  BTable, BFormCheckbox, BButton, BCard, BRow, BCol, BAvatar, BBadge,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import {header_token} from '@/views/token.js'
import {taobaoService_dotnet} from '@/views/global.js'
import axios from "axios";
import { onMounted, onUnmounted } from 'vue'

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    flatPickr,
    ScrollLoader
  },

  data() {
    return {
    }
  },

  created() {
  },

  methods: {

  }
}
</script>
<style>
</style>
